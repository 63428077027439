import styled from "styled-components"

import { GraphCard } from "src/components/Homes/DeviceDetails/Overview/GraphCard"
import { TNoiseInsightEventsByDate } from "src/data/noiseInsights/types/noiseInsightTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockType } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { colorScale } from "src/ui/colors"
import { BarChart } from "src/ui/Graphs/BarChart"
import { GenericTooltip } from "src/ui/Graphs/GenericTooltip"
import { TBarChartSingleSeriesData } from "src/ui/Graphs/graphTypes"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

const GRAPH_HEIGHT = 300

export function NoiseInsightGraphCard({
  data,
  isLoading,
  startDate,
  endDate,
}: {
  data: TNoiseInsightEventsByDate | undefined
  isLoading: boolean
  startDate: Date
  endDate: Date
}) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const hasData = !!(data?.data?.length ?? 0 > 0)

  function getGraphAxisOptions():
    | {
        xAxisOptions: Highcharts.XAxisOptions
        yAxisOptions: Highcharts.YAxisOptions
      }
    | undefined {
    if (!hasData) {
      return {
        xAxisOptions: {
          min: startDate.getTime(),
          max: endDate.getTime(),
          dateTimeLabelFormats: { month: "%b '%y" },
        },
        yAxisOptions: {
          min: 0,
          max: 10,
        },
      }
    }

    return {
      xAxisOptions: { dateTimeLabelFormats: { month: "%b '%y" } },
      yAxisOptions: {
        tickAmount: 5,
      },
    }
  }

  const totalEvents = data?.data.reduce(
    (acc, item) => acc + item.aggregation,
    0
  )

  const convertedData: TBarChartSingleSeriesData[] =
    data?.data.map((item) => [
      new Date(item.grouped_by).getTime(),
      item.aggregation,
    ]) ?? []

  return (
    <GraphCard
      title={t(langKeys.ongoing_noise_events)}
      rightInfo={totalEvents}
      hideGraphBorder={false}
      hidden={false}
    >
      {isLoading ? (
        <LoadingBox>
          <MCircularProgress />
        </LoadingBox>
      ) : (
        <BarChart
          dataSeriesType="single"
          data={convertedData}
          timezone={"UTC"}
          height={GRAPH_HEIGHT}
          stackSeries={true}
          clockType={user.clock_type}
          showLegend={false}
          borderRadius={2}
          borderColor={colorScale.gaff[600]}
          borderWidth={1}
          fillColor={colorScale.gaff[100]}
          seriesHoverBrightness={0.03}
          interactive={true}
          {...getGraphAxisOptions()}
          tooltip={{
            decimals: 0,
            formatter: ({ date, points }) => {
              return (
                <Tooltip
                  date={date}
                  clockType={user.clock_type}
                  numberOfEvents={points?.[0]?.total ?? 0}
                  percentage={
                    totalEvents
                      ? Number(
                          (
                            ((points?.[0]?.total ?? 0) / (totalEvents ?? 1)) *
                            100
                          ).toFixed(1)
                        )
                      : 0
                  }
                />
              )
            },
            unit: "",
          }}
        />
      )}
    </GraphCard>
  )
}

function Tooltip({
  date,
  clockType,
  numberOfEvents,
  percentage,
}: {
  date: Date
  clockType: TClockType
  numberOfEvents: number
  percentage: number
}) {
  const { t, langKeys } = useTranslate()

  const formattedDate = formatDate({
    date: date.toISOString(),
    clockType: clockType,
    timezone: "UTC",
    excludeTime: true,
  })

  return (
    <GenericTooltip
      header={<MText variant="bodyS">{`${formattedDate}`}</MText>}
    >
      <MText variant="subtitleS">
        {numberOfEvents} {t(langKeys.ongoing_noise_events)}
      </MText>
      <MText color="tertiary">{`(${percentage}%)`}</MText>
    </GenericTooltip>
  )
}

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${GRAPH_HEIGHT}px;
`
