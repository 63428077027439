import styled from "styled-components"

import { GraphCard } from "src/components/Homes/DeviceDetails/Overview/GraphCard"
import { TNoiseInsightEventResolutionTime } from "src/data/noiseInsights/types/noiseInsightTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { colorScale } from "src/ui/colors"
import { BarChart } from "src/ui/Graphs/BarChart"
import { GenericTooltip } from "src/ui/Graphs/GenericTooltip"
import { TBarChartMultiSeriesData } from "src/ui/Graphs/graphTypes"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const GRAPH_HEIGHT = 300

export function NoiseInsightTimeGraph({
  data,
  isLoading,
}: {
  data: TNoiseInsightEventResolutionTime | undefined
  isLoading: boolean
}) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const totalEvents: number | undefined = data?.data.reduce(
    (acc, item) => acc + item.aggregation,
    0
  )

  // Get the % of events solved after each alert, and get the highest one and which level it was
  let highestSoleRateBucket:
    | { percentage: number; level: "0-10" | "10-20" | "20+" }
    | undefined
  if (totalEvents && totalEvents > 0 && data) {
    highestSoleRateBucket = data.data
      .map((item) => {
        return {
          percentage: Math.round((item.aggregation / totalEvents) * 100),
          level: item.grouped_by,
        }
      })
      .sort((a, b) => b.percentage - a.percentage)[0]
  }

  const alertToName = {
    "0-10": t(langKeys.first),
    "10-20": t(langKeys.second),
    "20+": t(langKeys.third),
  }

  const infoText = highestSoleRateBucket
    ? t(langKeys.noise_insights_percentage_noise_solved_after_alert, {
        percentage: highestSoleRateBucket?.percentage,
        alertLevel: alertToName[highestSoleRateBucket?.level],
      })
    : ""

  const groupingKeyToColor = {
    "0-10": colorScale.gaff[100],
    "10-20": colorScale.gaff[200],
    "20+": colorScale.gaff[300],
  }

  const graphData: TBarChartMultiSeriesData[] =
    data?.data.map((item) => {
      const name = t(langKeys.noise_insights_solved_after, {
        alertLevel: alertToName[item.grouped_by],
      })
      return {
        name,
        data: [[name, item.aggregation]],
        fillColor: groupingKeyToColor[item.grouped_by],
        borderColor: colorScale.gaff[600],
      }
    }) ?? []

  return (
    <GraphCard
      title={t(langKeys.noise_insights_time_to_resolution)}
      info={`${infoText}.`}
      hideGraphBorder={false}
      hidden={false}
      headerPadding={`${spacing.L} ${spacing.L} 0`}
      chartPadding={`${spacing.M}`}
    >
      {isLoading ? (
        <LoadingBox>
          <MCircularProgress />
        </LoadingBox>
      ) : (
        <BarChart
          dataSeriesType="multi"
          data={graphData}
          timezone={"UTC"}
          height={GRAPH_HEIGHT}
          stackSeries={true}
          clockType={user.clock_type}
          showLegend={false}
          borderRadius={2}
          borderColor={colorScale.gaff[600]}
          borderWidth={1}
          fillColor={colorScale.gaff[100]}
          seriesHoverBrightness={0.03}
          interactive={true}
          yAxisOptions={{
            tickAmount: 5,
          }}
          xAxisOptions={{
            type: "category",
          }}
          tooltip={{
            decimals: 0,
            formatter: ({ points }) => {
              if (!totalEvents) return <></>
              const point = points?.[0]
              const yValue = point?.total ?? 0
              return (
                <Tooltip
                  seriesName={point?.key} // Will be the series name
                  numberOfEvents={point?.total ?? 0}
                  percentage={Number(((yValue / totalEvents) * 100).toFixed(1))}
                />
              )
            },
            unit: "",
          }}
        />
      )}
    </GraphCard>
  )
}

function Tooltip({
  seriesName,
  numberOfEvents,
  percentage,
}: {
  seriesName?: string
  numberOfEvents: number
  percentage: number
}) {
  const { t, langKeys } = useTranslate()

  return (
    <GenericTooltip header={<MText variant="bodyS">{seriesName}</MText>}>
      <MText variant="subtitleS">
        {t(langKeys.noise_insights_noise_events, {
          count: numberOfEvents,
          number_of_events: numberOfEvents,
        })}
      </MText>
      <MText color="tertiary">{`(${percentage}%)`}</MText>
    </GenericTooltip>
  )
}

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${GRAPH_HEIGHT}px;
`
