import { noiseInsightKeys } from "src/data/noiseInsights/queries/noiseInsightQueryCache"
import {
  TNoiseInsightEventResolutionTimeFilter,
  TNoiseInsightEventsByHomeFilter,
  TNoiseInsightEventsFilter,
} from "src/data/noiseInsights/types/noiseInsightTypes"
import { useMinutQuery } from "src/hooks/minutQuery"

export function useFetchNoiseEventsByDate({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: TNoiseInsightEventsFilter
  options?: { enabled?: boolean; keepPreviousData?: boolean }
}) {
  return useMinutQuery<"/organizations/{organization_id}/analytics/aggregated_group/noise_events_by_date">(
    {
      queryPath: `/organizations/${orgId}/analytics/aggregated_group/noise_events_by_date`,
      queryKey: noiseInsightKeys.eventsByDate({ orgId, filters }),
      filters: filters,
      options: options,
    }
  )
}

export function useFetchNoiseEventResolutionTime({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: TNoiseInsightEventResolutionTimeFilter
  options?: { enabled?: boolean; keepPreviousData?: boolean }
}) {
  return useMinutQuery<"/organizations/{organization_id}/analytics/aggregated_group/noise_event_solution_time">(
    {
      queryPath: `/organizations/${orgId}/analytics/aggregated_group/noise_event_solution_time`,
      queryKey: noiseInsightKeys.eventResolutionTime({ orgId, filters }),
      filters,
      options,
    }
  )
}

export function useFetchNoiseEventsByHome({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: TNoiseInsightEventsByHomeFilter
  options?: { enabled?: boolean; keepPreviousData?: boolean }
}) {
  return useMinutQuery<"/organizations/{organization_id}/analytics/aggregated_group/noise_events_by_home">(
    {
      queryPath: `/organizations/${orgId}/analytics/aggregated_group/noise_events_by_home`,
      queryKey: noiseInsightKeys.eventsByHome({ orgId, filters }),
      filters,
      options,
    }
  )
}
