import { useState } from "react"
import styled from "styled-components"

import { Pager } from "src/components/Pager/Pager"
import { NoiseInsightsDialog } from "src/components/Reports/NoiseInsights/NoiseInsightsDialog"
import { NoiseInsightsHomeRow } from "src/components/Reports/NoiseInsights/NoiseInsightsHomeRow"
import { TNoiseInsightEventsByHome } from "src/data/noiseInsights/types/noiseInsightTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseInsightHomeTable({
  data,
  isLoading,
  paging,
  startDate,
  endDate,
}: {
  data: TNoiseInsightEventsByHome | undefined
  isLoading: boolean
  paging: {
    limit: number
    offset: number
    total_count: number
    setOffset: (offset: number) => void
  }
  startDate: Date
  endDate: Date
}) {
  const { langKeys, t } = useTranslate()
  const [selectedHome, setSelectedHome] = useState<string | null>(null)

  const actualData = data?.data.data

  const rows =
    actualData?.map((item, index) => (
      <NoiseInsightsHomeRow
        item={item}
        isLast={index === actualData.length - 1}
        key={item.grouped_by}
        onGraphDialogOpen={(homeId: string) => setSelectedHome(homeId)}
        startDate={startDate}
        endDate={endDate}
      />
    )) ?? []

  return (
    <>
      <CardWrapper>
        <MCard boxShadow={false} border={true}>
          <Header>
            <div>
              <MText variant="heading3">
                {t(langKeys.noise_insights_units_affected_by_noise)}
              </MText>
              {data?.max_events_for_home && (
                <MText variant="bodyS" color="secondary">
                  {t(langKeys.noise_insights_no_unit_had_more_than_events, {
                    count: data?.max_events_for_home,
                    number_of_events: data?.max_events_for_home,
                  })}
                </MText>
              )}
            </div>
          </Header>

          <NoiseInsightHomeTableContent rows={rows} loading={isLoading} />
        </MCard>
      </CardWrapper>

      <Pager
        limit={paging.limit}
        offset={paging.offset}
        setOffset={paging.setOffset}
        totalCount={paging.total_count}
      />

      {!!selectedHome && (
        <NoiseInsightsDialog
          open={!!selectedHome}
          onClose={() => setSelectedHome(null)}
          homeId={selectedHome}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  )
}

function NoiseInsightHomeTableContent({
  rows,
  loading,
}: {
  rows: JSX.Element[]
  loading: boolean
}) {
  if (loading) {
    return (
      <div>
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
      </div>
    )
  }
  return (
    <Table style={{ display: "grid", gap: spacing.M }}>
      {rows.map((row, i) => (
        <div key={i}>{row}</div>
      ))}
    </Table>
  )
}

const CardWrapper = styled.div`
  margin-bottom: ${spacing.M};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${spacing.M};
`

const Table = styled.div`
  display: grid;
  gap: ${spacing.M};
  container-type: inline-size;
`
