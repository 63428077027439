import styled from "styled-components"

import { mColors } from "@minuthq/meatball-ui-react"
import { IconButton } from "@minuthq/meatball-ui-react"

import { breakpoint } from "src/constants/breakpoints"
import { TNoiseInsightsEvent } from "src/data/noiseInsights/types/noiseInsightTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import GraphPlotIcon from "src/ui/icons/graph-plot.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function NoiseInsightsHomeRow({
  item,
  isLast,
  onGraphDialogOpen,
  startDate,
  endDate,
}: {
  item: TNoiseInsightsEvent
  isLast: boolean
  onGraphDialogOpen: (homeId: string) => void
  startDate: Date
  endDate: Date
}) {
  const { langKeys, t } = useTranslate()

  return (
    <Row $isLast={isLast}>
      <NameWrapper>
        <InternalLink
          to={Routes.HomeLog.location({
            homeId: item.grouped_by,
            startDate,
            endDate,
            eventFilter: ["noise"],
          })}
        >
          {item.metadata.home_name}
        </InternalLink>

        {item.metadata.homegroup_name && (
          <MText color="tertiary" variant="bodyS">
            &bull; {item.metadata.homegroup_name}
          </MText>
        )}
      </NameWrapper>

      <NoiseEventsWrapper variant="bodyS">
        {t(langKeys.noise_insights_noise_events, {
          count: item.aggregation,
          number_of_events: item.aggregation,
        })}
      </NoiseEventsWrapper>

      <ViewGraphWrapper>
        <MTooltip title={t(langKeys.view_graph)}>
          <IconButton
            variant="subtle"
            Icon={GraphPlotIcon}
            size="small"
            onClick={() => onGraphDialogOpen(item.grouped_by)}
          />
        </MTooltip>
      </ViewGraphWrapper>
    </Row>
  )
}

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.XS};
`

const ViewGraphWrapper = styled.div`
  grid-area: view_graph;
`

const NoiseEventsWrapper = styled(MText)`
  grid-area: noise_events;
`

const Row = styled.div<{ $isLast: boolean }>`
  display: grid;
  grid-template-areas: "home_name noise_events view_graph";
  grid-template-columns: 1fr auto auto;

  @container (${breakpoint.smallDown}) {
    grid-template-areas:
      "home_name view_graph"
      "noise_events view_graph";
    grid-template-columns: 1fr auto auto;
  }

  align-items: center;
  column-gap: ${spacing.M};
  row-gap: ${spacing.XS};
  padding-bottom: ${({ $isLast }) => ($isLast ? "0" : spacing.S)};
  border-bottom: ${({ $isLast }) =>
    $isLast ? "unset" : `1px solid ${mColors.divider}`};
`
