import {
  TNoiseInsightEventResolutionTimeFilter,
  TNoiseInsightEventsByHomeFilter,
  TNoiseInsightEventsFilter,
} from "src/data/noiseInsights/types/noiseInsightTypes"

export const noiseInsightKeys = {
  all() {
    return ["noise-insights"] as const
  },

  eventsByDate({
    orgId,
    filters,
  }: {
    orgId: string
    filters: TNoiseInsightEventsFilter
  }) {
    return [...this.all(), "eventsByDate", orgId, filters] as const
  },

  eventResolutionTime({
    orgId,
    filters,
  }: {
    orgId: string
    filters: TNoiseInsightEventResolutionTimeFilter
  }) {
    return [...this.all(), "eventResolutionTime", orgId, filters] as const
  },

  eventsByHome({
    orgId,
    filters,
  }: {
    orgId: string
    filters: TNoiseInsightEventsByHomeFilter
  }) {
    return [...this.all(), "eventsByHome", orgId, filters] as const
  },
} as const
