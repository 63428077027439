import { Button } from "@minuthq/meatball-ui-react"
import { differenceInDays } from "date-fns"

import { AppStoreLogos } from "src/components/EmptyState/AppStoreLogos"
import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

import Desktop from "./images/noise-insight-no-events.svg"
import Mobile from "./images/noise-insight-no-events-mobile.svg"
import NoiseInsightsEmptyStateImage from "./images/noise-insights-graph-empty-state.svg"
import UnmonitoredHomesImage from "./images/noise-insights-no-monitored-homes.svg"

export function NoNoiseInsightsNoDevices() {
  const { tPlaceholder } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={NoiseInsightsEmptyStateImage}
      title={tPlaceholder("Analyze noise in your properties")}
      description={tPlaceholder(
        "Your noise data will show up here once you install a device. You can do that using our mobile app."
      )}
      content={<AppStoreLogos />}
      imageSpacing={spacing.M}
    />
  )
}

export function NoNoiseInsightsNoEvents({
  dateRange,
}: {
  dateRange: IGraphDateRange
}) {
  const { tPlaceholder } = useTranslate()

  const daysInRange = differenceInDays(dateRange.endDate, dateRange.startDate)

  return (
    <EmptyStateWithImage
      Image={Desktop}
      MobileImage={Mobile}
      title={tPlaceholder(`That's ${daysInRange} peaceful nights`)}
      description={tPlaceholder(
        `No noise events in the selected range. That's ${daysInRange} peaceful nights for you and your neighbors.`
      )}
      imageSpacing={spacing.M}
      limitImageWidth={false}
    />
  )
}

export function NoNoiseInsightsNoMonitoredHomes() {
  const { tPlaceholder } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={UnmonitoredHomesImage}
      title={tPlaceholder("All your units are unmonitored")}
      description={tPlaceholder(
        "Bring your devices back online to analyze noise trends."
      )}
      content={
        <>
          <Button>{tPlaceholder("View unmonitored homes")}</Button>
        </>
      }
      imageSpacing={spacing.M}
    />
  )
}
