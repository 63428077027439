import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { SettingsRoutes } from "src/router/settingsRoutes"
import { mColors } from "src/ui/colors"
import LightIcon from "src/ui/icons/lightbulb.svg"
import { MLink, MLinkStyleProps } from "src/ui/Link/MLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseInsightTipsCard() {
  const { t, langKeys } = useTranslate()

  const devicesText = t(langKeys.sensor, {
    count: 2,
  }).toLowerCase()

  return (
    <MCard
      boxShadow={false}
      border={false}
      backgroundColor={mColors.primaryLight}
      padding={spacing.L}
    >
      <Title>
        <LightIcon width={24} />
        <MText variant="heading3">{t(langKeys.noise_insights_tip_title)}</MText>
      </Title>
      <TextStyles variant="bodyS">
        <ReactMarkdown
          components={{
            a: ({ href, children }) => (
              <MLink color="unset" href={href ?? ""}>
                {children}
              </MLink>
            ),
          }}
        >
          {t(langKeys.noise_insights_tip_body, {
            devices: `[${devicesText}](${Routes.Sensors.location().pathname})`,
            configure_here: `[${t(langKeys.noise_insights_configure_here)}](${SettingsRoutes.SettingsPresetsNoise.location().pathname})`,
          })}
        </ReactMarkdown>
      </TextStyles>
    </MCard>
  )
}

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const TextStyles = styled(MText)<MLinkStyleProps>`
  ul {
    padding-left: 1rem;
  }
`
