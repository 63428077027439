import { useState } from "react"
import styled from "styled-components"

import { spacing } from "@minuthq/meatball-ui-react"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { useGetGraphData } from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import { ExportDeviceDataDialog } from "src/components/Homes/ExportDeviceDataDialog/ExportDeviceDataDialog"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { MDialog } from "src/ui/Dialog/MDialog"
import { LineChart } from "src/ui/Graphs/LineChart"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"

type NoiseInsightsDialogProps = {
  onClose: () => void
  open: boolean
  homeId: string
  startDate: Date
  endDate: Date
}

function NoiseGraphTitle({
  dateRange,
  handleDateRangeChange,
}: {
  dateRange: IGraphDateRange
  handleDateRangeChange: (dateRange: IGraphDateRange) => void
}) {
  const { t, langKeys } = useTranslate()

  const unitSymbol = `dB`
  const title = `${t(langKeys.sound_noise_monitoring)} (${unitSymbol})`

  return (
    <GraphTitleBox>
      <MText variant="heading2">{title}</MText>
      <DateRangePicker
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDateRangeChange={handleDateRangeChange}
        dateRangeProps={{
          minBookingDays: 2,
          numberOfMonths: 1,
        }}
        defaultPresetKey="DAY"
      />
    </GraphTitleBox>
  )
}

function NoiseGraphCard({
  home,
  device,
  dateRange,
}: {
  home: THome
  device: TDevice
  dateRange: IGraphDateRange
}) {
  const { sound } = useGetGraphData({
    dateRange,
    device,
    home,
  })
  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  const user = useGetUser()

  const { t, langKeys } = useTranslate()

  const unitSymbol = `dB`

  return (
    <>
      <GraphHeader>
        <InternalLink
          to={Routes.SensorsWithDeviceId.location(device.device_id)}
        >
          {device.description}
        </InternalLink>
        <MButton variant="minimal" onClick={() => setExportDialogOpen(true)}>
          {t(langKeys.export)}
        </MButton>
      </GraphHeader>

      <Chart>
        {sound.isLoading ? (
          <LoadingBox>
            <MCircularProgress />
          </LoadingBox>
        ) : (
          sound.data && (
            <LineChart
              data={sound.data}
              tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
              timezone={home.timezone}
              clockType={user.clock_type}
              thresholds={sound.thresholds}
              dateRange={dateRange}
            />
          )
        )}
      </Chart>
      <ExportDeviceDataDialog
        device={device}
        home={home}
        open={exportDialogOpen}
        dateRange={dateRange}
        onClose={() => setExportDialogOpen(false)}
      />
    </>
  )
}

export function NoiseInsightsDialog({
  open,
  onClose,
  homeId,
  startDate,
  endDate,
}: NoiseInsightsDialogProps) {
  const { orgId } = useOrganization()

  const fetchHome = useFetchHome({
    orgId,
    homeId,
  })

  const home = fetchHome.data

  const fetchDevices = useFetchDevices({
    orgId: orgId,
    filter: {
      home_ids: [homeId],
      sort: "description",
    },
    options: {
      enabled: !!orgId,
    },
  })

  const [dateRange, setDateRange] = useState({
    startDate,
    endDate,
  })

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })
  }

  const devices = fetchDevices.data?.devices || []

  const isLoading = fetchDevices.isInitialLoading || fetchHome.isLoading

  if (isLoading) {
    return null
  }

  return (
    <>
      {!!home && (
        <MDialog
          title={
            <NoiseGraphTitle
              handleDateRangeChange={handleDateRangeChange}
              dateRange={dateRange}
            />
          }
          open={open}
          onClose={onClose}
          width="full"
        >
          <ContentBox>
            {devices.map((device) => {
              return (
                <NoiseGraphCard
                  key={device.device_id}
                  home={home}
                  device={device}
                  dateRange={dateRange}
                />
              )
            })}
          </ContentBox>
        </MDialog>
      )}
    </>
  )
}

const ContentBox = styled.div`
  min-height: 600px;
`

const GraphHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spacing.XL} ${spacing.XL} 0;
`

const Chart = styled.div`
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
`

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const GraphTitleBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
