import { useRef, useState } from "react"
import styled from "styled-components"

import { mColors } from "@minuthq/meatball-ui-react"
import { differenceInDays, endOfDay, startOfDay, subDays } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import { useResizeObserver } from "usehooks-ts"

import {
  NoNoiseInsightsNoDevices,
  NoNoiseInsightsNoEvents,
  NoNoiseInsightsNoMonitoredHomes,
} from "src/components/EmptyState/NoNoiseInsights"
import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { InsightFilters } from "src/components/Reports/InsightFilters"
import { NoiseInsightGraphCard } from "src/components/Reports/NoiseInsights/NoiseInsightGraphCard"
import { NoiseInsightHomeTable } from "src/components/Reports/NoiseInsights/NoiseInsightHomeTable"
import { NoiseInsightTimeGraph } from "src/components/Reports/NoiseInsights/NoiseInsightTimeGraph"
import { NoiseInsightTipsCard } from "src/components/Reports/NoiseInsights/NoiseInsightTipsCard"
import { TimezoneSelect } from "src/components/Reports/TimezoneSelect"
import { breakpoint } from "src/constants/breakpoints"
import { useAppData } from "src/context/useAppData"
import { usePostReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import {
  useFetchNoiseEventResolutionTime,
  useFetchNoiseEventsByDate,
  useFetchNoiseEventsByHome,
} from "src/data/noiseInsights/queries/noiseInsightQueries"
import {
  TNoiseInsightEventResolutionTime,
  TNoiseInsightEventsByDate,
  TNoiseInsightEventsByHome,
} from "src/data/noiseInsights/types/noiseInsightTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import {
  TReportRequestBody,
  usePostReportRequest,
} from "src/data/reports/reportQueries"
import { TTimezone } from "src/data/timezones/timezones"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { MButton } from "src/ui/Button/MButton"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import InfoIcon from "src/ui/icons/info.svg"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { downloadFromUrl } from "src/utils/downloadUtil"
import { getStoredSelectedTimezone } from "src/utils/l10n"

function convertDateToTimezone(date: Date, timezone: TTimezone): string {
  return zonedTimeToUtc(date, timezone.tzCode).toISOString()
}

const MOBILE_BREAKPOINT = 500

export function NoiseInsights() {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const { available: reportsAvailable } = useFeatureAvailability({
    feature: "reports",
  })

  const viewRef = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({
    ref: viewRef,
    box: "border-box",
  })

  const [selectedHomegroup, setSelectedHomegroup] =
    useState<TSelectOption<string> | null>(null)

  const [timezone, setTimezone] = useState<TTimezone>(
    getStoredSelectedTimezone().timezone
  )

  const [dateRange, setDateRange] = useState<IGraphDateRange>({
    startDate: startOfDay(subDays(new Date(), 30)),
    endDate: endOfDay(new Date()),
  })

  // Fetch devices to determine if an empty state should be shown
  const fetchDevices = useFetchDevices({ orgId, filter: { limit: 1 } })
  const devices = fetchDevices.data?.devices ?? []
  const hasDevicesInstalled = devices.length > 0

  const fetchUnmonitoredHomesCount = useFetchHomesCount({
    orgId,
    filter: { offline_sensors: "all" },
  })
  const { numberOfHomes } = useAppData()
  const allHomesAreUnmonitored =
    fetchUnmonitoredHomesCount.data === numberOfHomes

  const filters = {
    from: convertDateToTimezone(dateRange.startDate, timezone),
    to: convertDateToTimezone(dateRange.endDate, timezone),
    homegroup_id: selectedHomegroup?.value,
  }

  const diffDays = differenceInDays(
    new Date(filters.to),
    new Date(filters.from)
  )

  const resolution = width < MOBILE_BREAKPOINT || diffDays > 30 ? "week" : "day"

  // Fetch events day-by-day/week-by-week to pass to the graph card
  const fetchEventsByDate = useFetchNoiseEventsByDate({
    orgId,
    filters: {
      ...filters,
      resolution: resolution,
    },
  })

  // Fetch resolution time for the selected homegroup
  const fetchResolutionTime = useFetchNoiseEventResolutionTime({
    orgId,
    filters,
  })

  const { offset, setOffset } = useUrlPager()
  const limit = 5
  // Fetch events by home for the selected homegroup
  const fetchEventsByHome = useFetchNoiseEventsByHome({
    orgId,
    filters: {
      ...filters,
      offset,
      limit,
    },
  })

  if (!reportsAvailable) {
    return null
  }

  // If there are any events at all then there will be at least one home in this list
  const hasNoEvents = fetchEventsByHome.data?.data.data.length === 0

  const breadcrumbs: IBreadcrumb[] = [
    { label: t(langKeys.report_plural), to: Routes.Reports.location() },
    {
      label: t(langKeys.noise_monitoring_insights),
      to: Routes.NoiseInsights.location(),
    },
  ]

  if (
    fetchEventsByDate.isError ||
    fetchResolutionTime.isError ||
    fetchEventsByHome.isError
  ) {
    throw new Error("Error fetching noise insights")
  }

  return (
    <div ref={viewRef}>
      <BreadcrumbView
        breadcrumbs={breadcrumbs}
        title={
          <MText variant="heading1">
            {t(langKeys.noise_monitoring_insights)}
          </MText>
        }
        description={""}
        actionBar={
          <ActionBar
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedHomegroup={selectedHomegroup}
            setSelectedHomegroup={setSelectedHomegroup}
            hasDevicesInstalled={hasDevicesInstalled}
          />
        }
      >
        <NoiseInsightsContent
          isLoading={
            fetchEventsByDate.isLoading ||
            fetchResolutionTime.isLoading ||
            fetchEventsByHome.isLoading
          }
          hasDevicesInstalled={hasDevicesInstalled}
          hasNoEvents={hasNoEvents}
          allHomesAreUnmonitored={allHomesAreUnmonitored}
          dateRange={dateRange}
          eventsByDateData={fetchEventsByDate.data}
          eventsByDateLoading={fetchEventsByDate.isLoading}
          resolutionTimeData={fetchResolutionTime.data}
          resolutionTimeLoading={fetchResolutionTime.isLoading}
          eventsByHomeData={fetchEventsByHome.data}
          eventsByHomeLoading={fetchEventsByHome.isLoading}
          timezone={timezone}
          setTimezone={setTimezone}
          paging={{
            limit,
            offset,
            total_count: fetchEventsByHome.data?.paging.total_count ?? 0,
            setOffset,
          }}
        />
      </BreadcrumbView>
    </div>
  )
}

interface NoiseInsightsContentProps {
  isLoading: boolean
  hasDevicesInstalled: boolean
  hasNoEvents: boolean
  allHomesAreUnmonitored: boolean
  dateRange: IGraphDateRange
  eventsByDateData?: TNoiseInsightEventsByDate
  eventsByDateLoading: boolean
  resolutionTimeData?: TNoiseInsightEventResolutionTime
  resolutionTimeLoading: boolean
  eventsByHomeData?: TNoiseInsightEventsByHome
  eventsByHomeLoading: boolean
  timezone: TTimezone
  setTimezone: (timezone: TTimezone) => void
  paging: {
    limit: number
    offset: number
    total_count: number
    setOffset: (offset: number) => void
  }
}

function NoiseInsightsContent({
  isLoading,
  hasDevicesInstalled,
  hasNoEvents,
  allHomesAreUnmonitored,
  dateRange,
  eventsByDateData,
  eventsByDateLoading,
  resolutionTimeData,
  resolutionTimeLoading,
  eventsByHomeData,
  eventsByHomeLoading,
  timezone,
  setTimezone,
  paging,
}: NoiseInsightsContentProps) {
  const { t, langKeys } = useTranslate()

  if (isLoading) {
    return (
      <LoadingBox>
        <MCircularProgress />
      </LoadingBox>
    )
  }
  if (!hasDevicesInstalled) {
    return <NoNoiseInsightsNoDevices />
  }
  if (allHomesAreUnmonitored) {
    return <NoNoiseInsightsNoMonitoredHomes />
  }
  if (hasNoEvents) {
    return <NoNoiseInsightsNoEvents dateRange={dateRange} />
  }

  return (
    <VerticalWrapper>
      <NoiseInsightGraphCard
        data={eventsByDateData}
        isLoading={eventsByDateLoading}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
      />
      <Wrapper>
        <GraphWrapper>
          <NoiseInsightTimeGraph
            data={resolutionTimeData}
            isLoading={resolutionTimeLoading}
          />
        </GraphWrapper>
        <GraphWrapper>
          <NoiseInsightHomeTable
            data={eventsByHomeData}
            isLoading={eventsByHomeLoading}
            paging={paging}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        </GraphWrapper>
      </Wrapper>
      <NoiseInsightTipsCard />
      <TimezoneContent>
        <MText variant="body">{t(langKeys.timezone)}: </MText>
        <TimezoneSelect timezone={timezone} onChange={setTimezone} />
      </TimezoneContent>
    </VerticalWrapper>
  )
}

function ActionBar({
  dateRange,
  setDateRange,
  selectedHomegroup,
  setSelectedHomegroup,
  hasDevicesInstalled,
}: {
  dateRange: IGraphDateRange
  setDateRange: (dateRange: IGraphDateRange) => void
  selectedHomegroup: TSelectOption<string> | null
  setSelectedHomegroup: (option: TSelectOption<string> | null) => void
  hasDevicesInstalled: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { available: reportsProAvailable, required_plan_types } =
    useFeatureAvailability({
      feature: "reports_pro",
    })

  const postReportRequest = usePostReportRequest()
  const postReportDownloadClicked = usePostReportDownloadClicked()

  function handleDownloadReport() {
    const requestData: TReportRequestBody = {
      report_type: "noise",
    }

    if (reportsProAvailable) {
      postReportDownloadClicked.mutate({ type: "filtered_noise" })
      requestData.homegroup_id = selectedHomegroup?.value
      requestData.start_at = dateRange.startDate?.toISOString()
      requestData.end_at = dateRange.endDate?.toISOString()
    } else {
      postReportDownloadClicked.mutate({ type: "basic_noise" })
    }

    postReportRequest.mutate(requestData, {
      onSuccess: ({ url }) => {
        downloadFromUrl(url)
      },
    })
  }

  if (!hasDevicesInstalled) {
    return null
  }

  return (
    <ActionBarWrapper>
      <InsightFilters
        context="noise_analytics"
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedOption={selectedHomegroup}
        setSelectedOption={setSelectedHomegroup}
        hidden={!hasDevicesInstalled}
        disabled={!reportsProAvailable}
        showUpgradeBlocker={!reportsProAvailable}
        requiredPlan={required_plan_types?.[0]}
        maxSelectableDays={180}
      />

      <DownloadWrapper>
        <MButton
          size="small"
          variant="subtle"
          onClick={handleDownloadReport}
          loading={postReportRequest.isLoading}
        >
          {t(langKeys.report_download_pdf)}
        </MButton>
        {postReportRequest.isError && (
          <ErrorWrapper>
            <InfoIcon width={20} height={20} color={mColors.systemErrorDark} />
            <MText variant="bodyS" color={"emergency"}>
              {t(langKeys.download_failed)}
            </MText>
          </ErrorWrapper>
        )}
      </DownloadWrapper>
    </ActionBarWrapper>
  )
}

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.XS2};
`

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS2};
`

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ActionBarWrapper = styled.div`
  display: flex;
  @container (${breakpoint.mediumDown}) {
    flex-direction: column;
  }
  gap: ${spacing.M};
  align-items: flex-start;
`

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XL2};
  container-type: inline-size;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.M};

  @container (${breakpoint.mediumDown}) {
    flex-direction: column;
  }
`

const GraphWrapper = styled.div`
  @container (${breakpoint.mediumUp}) {
    width: 50%;
  }
`

const TimezoneContent = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
